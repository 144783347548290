import React from "react";
import { Separator } from '@fluentui/react/lib/Separator';
import { TextField, MaskedTextField } from '@fluentui/react/lib/TextField';
import styled from "styled-components";
import { APIConfig } from "../../../../api";
import { Checkbox } from "@fluentui/react";

const StyledConnection = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;

  & .horizontal-frame-2 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    padding: 0px 20px;
    position: relative;
    width: 100%;
  }

  & .field-8 {
    align-self: stretch !important;
    flex: 1 !important;
    flex-grow: 1 !important;
    width: unset !important;
  }

  & .field-9 {
    align-self: stretch !important;
    align-items: flex-end;
  }

  @media (max-width: 480px) {
    .horizontal-frame-2 {
      align-items: center;
      align-self: stretch;
      display: flex;
      flex: 1 100%;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
      padding: 8px 20px;
      position: relative;
      width: 100%;
  }
`;

type TConnectionProps = {
  url: string,
  autoqmurl: string,
  customerid: string,
  accesscode: string,
  pipelineid: string,
  config: APIConfig,
  onChange: (value: React.SetStateAction<APIConfig>) => void;
};

export const Connection = ({ url, autoqmurl, customerid, accesscode, pipelineid, config, onChange }: TConnectionProps): JSX.Element => {
  return (
    <StyledConnection>
      <Separator alignContent="start">Service Access Configuration</Separator>
      <div className="horizontal-frame-2">
        <TextField label="AutoQM Analytics URL" required placeholder="Provide the AutoQM Analytics API URL" className="field-8"
          defaultValue={url}
          onChange={(e) => { onChange({ ...config, url: e.currentTarget.value }) }}
        />
        <TextField label="AutoQM API URL" required placeholder="Provide the AutoQM API URL" className="field-8"
          defaultValue={autoqmurl}
          onChange={(e) => { onChange({ ...config, autoqmurl: e.currentTarget.value }) }}
        />
        </div>
        <div className="horizontal-frame-2">
        <TextField label="Customer ID" placeholder="Provide the unique customer ID" required className="field-8"
          defaultValue={customerid}
          onChange={(e) => { onChange({ ...config, customerid: e.currentTarget.value }) }}
          type="password"
          canRevealPassword
          revealPasswordAriaLabel="Show password" />
        <TextField label="Access Code" placeholder="Provide the unique access code" required className="field-8"
          defaultValue={accesscode}
          onChange={(e) => { onChange({ ...config, accesscode: e.currentTarget.value }) }}
          type="password"
          canRevealPassword
          revealPasswordAriaLabel="Show password" />
      </div>
      <div className="horizontal-frame-2">
        <TextField label="Pieline Id" placeholder="Enter the id of the pipeline to be tested" required className="field-8"
          defaultValue={pipelineid}
          onChange={(e) => { onChange({ ...config, pipelineid: e.currentTarget.value }) }}
           />
        <Checkbox label="Persist" defaultChecked={config.persist} className="field-9"
          onChange={(e, checked) => { onChange({  ...config, persist: checked as boolean  }) }} />
      </div>
    </StyledConnection>
  );
};
