import React from "react";
import { Separator } from '@fluentui/react/lib/Separator';
import { TextField, MaskedTextField } from '@fluentui/react/lib/TextField';
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import styled from "styled-components";
import { APIConfig } from "../../../../api";

const StyledConnection = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;

  & .horizontal-frame-2 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    padding: 0px 20px;
    position: relative;
    width: 100%;
  }

  & .field-8 {
    align-self: stretch !important;
    flex: 1 !important;
    flex-grow: 1 !important;
    width: unset !important;
  }

  @media (max-width: 480px) {
    .horizontal-frame-2 {
      align-items: center;
      align-self: stretch;
      display: flex;
      flex: 1 100%;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
      padding: 8px 20px;
      position: relative;
      width: 100%;
  }
`;
const options: IDropdownOption[] = [
  { key: 'text', text: 'Text' },
  { key: 'voice', text: 'Voice' },
  { key: 'asreceived', text: 'Same as received' },
];

type TSpeechProps = {
  language: string,
  voice: string,
  phraselist: string,
  config: APIConfig,
  onChange: (value: React.SetStateAction<APIConfig>) => void;
};

export const Speech = ({ language, voice, phraselist, config, onChange }: TSpeechProps): JSX.Element => {
  return (
    <StyledConnection>
      <Separator alignContent="start">Speech Configuration</Separator>
      <div className="horizontal-frame-2">
        <TextField label="Language"
          defaultValue={language}
          onChange={(e) => { onChange({ ...config, language: e.currentTarget.value }) }}
          placeholder="Provide the STT/TTS Language Code" required className="field-8" />
        <TextField label="Voice"
          defaultValue={voice}
          onChange={(e) => { onChange({ ...config, voice: e.currentTarget.value }) }}
          placeholder="Provide the TTS Voice Code" required className="field-8" />
          <Dropdown label="Reply Mode" placeholder="Select Reply Mode" options={options} className="field-8"
                    defaultSelectedKey={config.replymode}
                    onChange={(e, option, index) => { onChange({ ...config, replymode: option?.key as string }) }}
                  />
        {/* <TextField label="Phrase List"
          defaultValue={phraselist}
          onChange={(e) => { onChange({ ...config, phraselist: e.currentTarget.value }) }}
          placeholder="Provide a  ML tunning phrase list separated by ;" className="field-8" /> */}
      </div>
    </StyledConnection>
  );
};
